import axios from 'axios'


  console.log('初始化 Axios Boot')

  const api = axios.create({ baseURL: 'https://api.example.com' })

// 请求拦截器
  api.interceptors.request.use(
    (config) => {
      // const token = util.cookies.get('token')
      const headers = config.headers;

      headers['Content-Type'] = headers['Content-Type'] || 'application/json';

      // const token = LocalStorage.getItem('token');
      // if (token) {
      //   headers.Authorization = `Bearer ${String(token)}`;
      // }

      config.headers = headers;
      config.timeout = 50000;
      config.baseURL = process.env.VUE_APP_API

      return config
    },
    (error) => {
      // 发送失败
      console.log('请求失败',error);
      return Promise.reject(error);
    },
  );


// 响应拦截器
  api.interceptors.response.use(
    (response) => {

      const headers = response.headers

      // if(headers['content-disposition']){
      // eslint-disable-next-line
      if(response.request.responseType === 'blob'){
        console.log('下载响应');

        let filename = 'aaa';
        // 是否为下载响应
        const info = headers['content-disposition'];

        if(info){
          filename = info.split('=').pop() || '';
          if (filename.indexOf('utf-8') === 0) {
            filename = decodeURIComponent(filename.substr(7));
          }
        }

        const blob = new Blob([response.data], {
          type: headers['content-type'],
        });

        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = filename;
        a.target = '_blank';
        a.style.display = 'none';
        // 下面这个写法兼容火狐
        a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        window.URL.revokeObjectURL(objectUrl);
      }

      return response;
    },
    (error) => {
      /* eslint-disable no-param-reassign */
      console.log('请求错误',error);
      let needLogin = false

      let title = '发生未知错误';
      let message = '';
      if (error.response) {

        if(error.response.data && error.response.data.message){
          message = error.response.data.message
        }


        // Server有响应
        switch (error.response.status) {
          case 400: title = '请求错误'; break;
          case 401:
            needLogin = true
            title = "未认证";
            message = '登录超时，请重新登录';
            break;
          case 403: title = '未被授权的访问'; break;
          case 404: title = '请求地址出错'; break;
          case 408: title = '请求超时'; break;
          case 422:
            title = '校验失败';
            break;
          case 500: title = '服务器内部错误'; break;
          case 501: title = '服务未实现'; break;
          case 502: title = '网关错误'; break;
          case 503: title = '服务不可用'; break;
          case 504: title = '网关超时'; break;
          case 505: title = 'HTTP版本不受支持'; break;
          default: break;
        }


      } else if (error.request){
        // 请求发出，服务器无影响
        message = '请求已发出，但服务器无响应。'
      } else {
        // 请求未发出 本地参数错误
        message = '请求未发出。'
      }

      // Dialog.create({
      //   title,
      //   message,
      //   html: true,
      //   persistent: true
      // }).onOk(() => {
      //   if(needLogin){
      //     redirect({ name: 'Login' })
      //     // { name: 'Login', query: { target: to.fullPath }
      //   }
      // });
      // 处理全局错误
      // const store = useErrorStore()
      // store.set(error)

      return error;
      // return Promise.reject(error);
    },
  );

  export default api;
